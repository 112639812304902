var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "search-box" },
      [
        _c("filter-condition", {
          attrs: { title: "所属类型", data: _vm.filterConditionData },
          on: { "on-go": _vm.onFilter },
        }),
        _c("div", { staticClass: "screen-status" }, [
          _c("span", { staticClass: "tit" }, [_vm._v("状态")]),
          _c("span", [_vm._v("：")]),
          _c("div", { staticClass: "status-box" }, [
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    return _vm.auctionStatus("")
                  },
                },
              },
              [_vm._v("全部")]
            ),
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    return _vm.auctionStatus("bidding")
                  },
                },
              },
              [_vm._v("进行中")]
            ),
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    return _vm.auctionStatus("preBid")
                  },
                },
              },
              [_vm._v("预告中")]
            ),
            _c(
              "p",
              {
                on: {
                  click: function ($event) {
                    return _vm.auctionStatus("expire")
                  },
                },
              },
              [_vm._v("已结束")]
            ),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.biddingList == null || _vm.biddingList.length !== 0
          ? [
              false
                ? _c(
                    "div",
                    { staticClass: "content" },
                    _vm._l(_vm.biddingList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "box" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: "/home/BiddingDetails",
                                  query: { autionInfoId: item.id },
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { class: ["box-in", "box-in-" + item.status] },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "image" },
                                    [
                                      _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.picUrl },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.prodName)),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("数量"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("："),
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.amount + item.units)),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "line" }, [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("当前价"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("："),
                                    ]),
                                    _c("span", { staticClass: "text price" }, [
                                      _vm._v(
                                        "￥" +
                                          _vm._s(item.maxAmount || item.upPrice)
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("起拍价"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("："),
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v("￥" + _vm._s(item.upPrice)),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("结束时间"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("：" + _vm._s(item.endTime)),
                                    ]),
                                    _c("span", { staticClass: "text" }),
                                  ]),
                                  _c("div", { staticClass: "ident" }, [
                                    item.status === "bidding"
                                      ? _c("img", {
                                          attrs: {
                                            src: require("../image/进行中.png"),
                                          },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: require("../image/预告中.png"),
                                          },
                                        }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              false
                ? _c(
                    "div",
                    { staticClass: "content2" },
                    _vm._l(_vm.biddingList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "box" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  path: "/home/BiddingDetails",
                                  query: { autionInfoId: item.id },
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "box-in" }, [
                                _c(
                                  "div",
                                  { staticClass: "image" },
                                  [
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: item.picUrl },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "info" }, [
                                  _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.prodName)),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("规格型号"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("："),
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.prodModels)),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", { staticClass: "tit" }, [
                                      _vm._v("数量"),
                                    ]),
                                    _c("span", { staticClass: "colon" }, [
                                      _vm._v("："),
                                    ]),
                                    _c("span", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.amount + item.units)),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "price" }, [
                                    _vm._v(
                                      "￥" +
                                        _vm._s(item.maxAmount || item.upPrice)
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "ident" }, [
                                  item.status === "bidding"
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../image/进行中.png"),
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("../image/预告中.png"),
                                        },
                                      }),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "bidding-box" },
                _vm._l(_vm.biddingList, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "bidding-item" },
                    [
                      _c("div", { staticClass: "title" }, [
                        item.status === "preBid"
                          ? _c("div", { staticClass: "status pre-bid" }, [
                              _vm._v("预告中"),
                            ])
                          : _vm._e(),
                        item.status === "bidding"
                          ? _c("div", { staticClass: "status bidding" }, [
                              _vm._v("竞卖中"),
                            ])
                          : _vm._e(),
                        item.status === "bided"
                          ? _c("div", { staticClass: "status bided" }, [
                              _vm._v("已结束"),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.prodName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "bidding-item-top" }, [
                        _c("div", { staticClass: "img-box" }, [
                          _c("img", { attrs: { src: item.picUrl, alt: "" } }),
                        ]),
                        _c("div", { staticClass: "info" }, [
                          _c("p", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "tit" }, [
                              _vm._v("规格型号"),
                            ]),
                            _vm._v("：" + _vm._s(item.prodModels)),
                          ]),
                          _c("p", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "tit" }, [
                              _vm._v("数量"),
                            ]),
                            _vm._v("：" + _vm._s(item.amount + item.units)),
                          ]),
                          _c("p", { staticClass: "info-item" }, [
                            _c("span", { staticClass: "tit" }, [
                              _vm._v("当前价"),
                            ]),
                            _vm._v(": "),
                            _c(
                              "span",
                              {
                                staticClass: "price",
                                class: { price2: item.status === "bided" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.dealPrice ||
                                      item.maxAmount ||
                                      item.upPrice
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      item.status === "bided"
                        ? _c(
                            "router-link",
                            {
                              staticClass: "btn2",
                              attrs: {
                                to:
                                  "/home/BiddingDetails?autionInfoId=" +
                                  item.id,
                              },
                            },
                            [_vm._v("查看详情")]
                          )
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "bidding-item-bottom",
                                class: { hide: item.status === "bidding" },
                              },
                              [
                                item.status === "preBid"
                                  ? _c("span", { staticClass: "tit" }, [
                                      _vm._v("距离开始:"),
                                    ])
                                  : _vm._e(),
                                item.status === "bidding"
                                  ? _c("span", { staticClass: "tit" }, [
                                      _vm._v("距离结束:"),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "time-box" }, [
                                  Number(item.day) > 0
                                    ? _c("span", [
                                        _vm._v(_vm._s(item.day) + "天"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    _vm._s(item.hour) +
                                      "小时" +
                                      _vm._s(item.minute) +
                                      "分钟" +
                                      _vm._s(item.second) +
                                      "秒 "
                                  ),
                                ]),
                              ]
                            ),
                            item.status === "bidding"
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      to:
                                        "/home/BiddingDetails?autionInfoId=" +
                                        item.id,
                                    },
                                  },
                                  [_vm._v("前往购买")]
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                }),
                0
              ),
            ]
          : _c("NoDataFound"),
      ],
      2
    ),
    _vm.page.total != 0
      ? _c(
          "div",
          { staticClass: "pager" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next",
                total: _vm.page.total,
                "page-size": _vm.page.pageSize,
                "current-page": _vm.page.currentPage,
              },
              on: { "current-change": _vm.handleCurrentChange },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }