<template>
  <div class="wrap">
    <div class="search-box">
      <filter-condition title="所属类型" :data="filterConditionData" @on-go="onFilter"></filter-condition>
      <div class="screen-status">
        <span class="tit">状态</span>
        <span>：</span>
        <div class="status-box">
          <p @click="auctionStatus('')">全部</p>
          <p @click="auctionStatus('bidding')">进行中</p>
          <p @click="auctionStatus('preBid')">预告中</p>
          <p @click="auctionStatus('expire')">已结束</p>
        </div>
      </div>
    </div>

    <div class="container">
      <!-- <el-date-picker
        v-model="publishTime"
        type="datetime"
        placeholder="发布时间"

        @change="pageList(0)"
        >

      </el-date-picker> -->
      <template v-if="biddingList == null || biddingList.length !== 0">
        <div class="content" v-if="false">
          <div class="box" v-for="item in biddingList" :key="item.id">
            <router-link :to="{path: '/home/BiddingDetails', query: {autionInfoId: item.id}}">
              <div :class="['box-in', 'box-in-' + item.status]">
                <div class="image">
                  <el-image :src="item.picUrl" style="width: 100%; height: 100%"></el-image>
                </div>
                <p class="name">{{ item.prodName }}</p>
                <p>
                  <span class="tit">数量</span>
                  <span class="colon">：</span>
                  <span class="text">{{ item.amount + item.units }}</span>
                </p>
                <p class="line">
                  <span class="tit">当前价</span>
                  <span class="colon">：</span>
                  <span class="text price">￥{{ item.maxAmount || item.upPrice }}</span>
                </p>
                <p>
                  <span class="tit">起拍价</span>
                  <span class="colon">：</span>
                  <span class="text">￥{{ item.upPrice }}</span>
                </p>
                <p>
                  <span class="tit">结束时间</span>
                  <span class="colon">：{{ item.endTime }}</span>
                  <span class="text"></span>
                </p>
                <div class="ident">
                  <img v-if="item.status === 'bidding'" src="../image/进行中.png" />
                  <img v-else src="../image/预告中.png" />
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="content2" v-if="false">
          <div class="box" v-for="item in biddingList" :key="item.id">
            <router-link :to="{path: '/home/BiddingDetails', query: {autionInfoId: item.id}}">
              <div class="box-in">
                <div class="image">
                  <el-image :src="item.picUrl" style="width: 100%; height: 100%"></el-image>
                </div>
                <div class="info">
                  <p class="name">{{ item.prodName }}</p>
                  <p>
                    <span class="tit">规格型号</span>
                    <span class="colon">：</span>
                    <span class="text">{{ item.prodModels }}</span>
                  </p>
                  <p>
                    <span class="tit">数量</span>
                    <span class="colon">：</span>
                    <span class="text">{{ item.amount + item.units }}</span>
                  </p>
                  <p class="price">￥{{ item.maxAmount || item.upPrice }}</p>
                </div>
                <div class="ident">
                  <img v-if="item.status === 'bidding'" src="../image/进行中.png" />
                  <img v-else src="../image/预告中.png" />
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="bidding-box">
          <div class="bidding-item" v-for="item in biddingList" :key="item.id">
            <div class="title">
              <div class="status pre-bid" v-if="item.status === 'preBid'">预告中</div>
              <div class="status bidding" v-if="item.status === 'bidding'">竞卖中</div>
              <div class="status bided" v-if="item.status === 'bided'">已结束</div>
              <p class="name">{{ item.prodName }}</p>
            </div>
            <div class="bidding-item-top">
              <div class="img-box">
                <img :src="item.picUrl" alt="" />
              </div>
              <div class="info">
                <p class="info-item"><span class="tit">规格型号</span>：{{ item.prodModels }}</p>
                <p class="info-item"><span class="tit">数量</span>：{{ item.amount + item.units }}</p>
                <p class="info-item">
                  <span class="tit">当前价</span>:
                  <!-- <span class="sign" :class="{sign2: item.status === 'bided'}">￥</span> -->
                  <span class="price" :class="{price2: item.status === 'bided'}">{{ item.dealPrice || item.maxAmount || item.upPrice }}</span>
                </p>
              </div>
            </div>

            <router-link class="btn2" :to="'/home/BiddingDetails?autionInfoId=' + item.id" v-if="item.status === 'bided'">查看详情</router-link>
            <template v-else>
              <div class="bidding-item-bottom" :class="{hide: item.status === 'bidding'}">
                <span class="tit" v-if="item.status === 'preBid'">距离开始:</span>
                <span class="tit" v-if="item.status === 'bidding'">距离结束:</span>
                <div class="time-box">
                  <span v-if="Number(item.day) > 0">{{ item.day }}天</span>{{ item.hour }}小时{{ item.minute }}分钟{{ item.second }}秒
                </div>
              </div>
              <router-link class="btn" :to="'/home/BiddingDetails?autionInfoId=' + item.id" v-if="item.status === 'bidding'">前往购买</router-link>
            </template>
          </div>
        </div>
      </template>

      <NoDataFound v-else></NoDataFound>
    </div>

    <!-- 分页器 -->
    <div class="pager" v-if="page.total != 0">
      <el-pagination background layout="prev, pager, next" :total="page.total" :page-size="page.pageSize" :current-page="page.currentPage" @current-change="handleCurrentChange"> </el-pagination>
    </div>
  </div>
</template>

<script>
import FilterCondition from '@/components/FilterCondition.vue';
import NoDataFound from '@/components/NoDataFound.vue';
import {formatNewDate} from '@/utils/util';
import {autionInfolist, categoryAll} from '../api';

export default {
  created() {
    //加载竞卖中心
    this.pageList(0);
    //加载竞卖商品类型
    this.typeList();
  },
  components: {
    'filter-condition': FilterCondition,
    NoDataFound
  },
  data() {
    return {
      filterConditionData: [
        {
          id: '',
          name: '',
          child: [
            {
              name: '',
              id: ''
            }
          ]
        }
      ],
      publishTime: undefined, //发布时间
      biddingList: null,
      // [
      // {
      //     id:undefined,
      //     isAution:undefined,
      //     amount:undefined,
      //     continueTime:undefined,
      //     dtsCategoryId:undefined,
      //     picUrl:undefined,
      //     prodName:undefined,
      //     startTime:undefined,
      //     upPrice:undefined,
      //     maxAmount:undefined,
      //     endTime:undefined,
      //       type: undefined,
      //     clientName:undefined,
      //     offAddr:undefined,
      //     socialCreditCode:undefined,
      //     tel:undefined,
      //     businessLicenseImages:undefined
      // },

      // ],
      page: {
        currentPage: 1,
        total: 0,
        pageSize: 15,
        pageCount: 0
      },
      _timeout: null,
      screenStatus: ''
    };
  },
  methods: {
    bidingStatus(startTime, endTime) {
      if (formatNewDate(startTime) > new Date()) {
        return 'preBid';
      }
      if (formatNewDate(startTime) < new Date() && formatNewDate(endTime) > new Date()) {
        return 'bidding';
      }
      return 'bided';
    },
    // 触发分页器
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.pageList(1);
    },
    //日期格式转换
    convertDate(date) {
      let d = formatNewDate(date); //传入时间
      let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;

      let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

      let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();

      let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();

      let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();

      let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times;
    },
    //预告中 ： 进行中 事件
    auctionStatus(status) {
      this.screenStatus = status;
      this.pageList(0);
    },

    //type 0 代表查询按钮点击进来的，默认显示第一页数据
    //      1 代表分页点进来的
    pageList(type) {
      const _this = this;
      if (this.publishTime != undefined) {
        this.publishTime = this.convertDate(this.publishTime);
      }
      if (type == 0) {
        this.page.currentPage = 1;
      }
      autionInfolist({
        dtsCategoryId: this.categoryId,
        startTime: this.publishTime,
        page: this.page.currentPage,
        limit: this.page.pageSize,
        screenStatus: this.screenStatus
        // prodName: this.searchForm.prodName
      }).then(res => {
        if (res.errno == 0) {
          _this.page.total = res.data.total;

          let items = res.data.items;
          items.forEach((item, index) => {
            if (_this.bidingStatus(item.startTime, item.endTime) === 'preBid') {
              // 预告中
              items[index] = Object.assign(item, {isOver: false, status: 'preBid'});
            } else if (_this.bidingStatus(item.startTime, item.endTime) === 'bidding') {
              // 进行中
              items[index] = Object.assign(item, {isOver: false, status: 'bidding'});
            } else if (_this.bidingStatus(item.startTime, item.endTime) === 'bided') {
              // 已结束
              items[index] = Object.assign(item, {isOver: true, status: 'bided'});
            }
            if (item.dealPrice != null) {
              items[index] = Object.assign(item, {isOver: true, status: 'bided'});
            }

            // 计算当前价
            if (item.status === 'bidding' || item.status === 'bided') {
              const now = Date.parse(new Date());
              const overMsec = now - Date.parse(formatNewDate(item.startTime));
              const overSecond = parseInt(overMsec / 1000 / 60);
              if (overSecond < item.continueTime) {
                items[index].maxAmount = Number(item.upPrice) - overSecond * Number(item.failTactics);
                items[index].maxAmount=this.formatMoney(items[index].maxAmount)
              } else {
                items[index].maxAmount = Number(item.upPrice) - Number(item.continueTime) * Number(item.failTactics);
                items[index].maxAmount=this.formatMoney(items[index].maxAmount)
              }
              if (item.maxAmount < item.bottomPrice) {
                items[index].maxAmount = item.bottomPrice;
                items[index].maxAmount=this.formatMoney(items[index].maxAmount)
              }

            }


               if (item.dealPrice!==undefined) {
                  item.dealPrice=this.formatMoney(item.dealPrice)
                }


          });
          _this.biddingList = items;
          _this.countdown_fun();
        }
      });
    },
    formatMoney(value) {
      //// 金额转换
      if (value !== undefined && value.toString().indexOf('.') !== -1) {
        if (value !== undefined && value.toString().split('.')[1].length == 2) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '亿元');
          } 
          
        else  if ( value !== undefined && value.toString().split('.')[0].length == 8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 7) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==7 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          } 
          else  if ( value !== undefined && value.toString().split('.')[0].length ==5) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          } 
          
        else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
          /////
        } else if (value !== undefined && value.toString().split('.')[1].length == 1) {
          if (value !== undefined && value.toString().split('.')[0].length >= 9) {
            value = ((value / 100000000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '亿元');
          }
          else if (  value !== undefined && value.toString().split('.')[0].length ==8) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 6) + '万元');
          }      
          else if ( value !== undefined && value.toString().split('.')[0].length ==7) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 5) + '万元');
          }    
          else if ( value !== undefined && value.toString().split('.')[0].length ==6) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 4) + '万元');
          }  
          else if (value !== undefined && value.toString().split('.')[0].length ==5 ) {
            value = ((value / 10000) * 100) / 100;
            return (value = value.toString().slice(0, 3) + '万元');
          } 
          // else if (9 > value !== undefined && value.toString().split('.')[0].length > 4) {
          //   value = ((value / 10000) * 100) / 100;
          //   return (value = value.toString().slice(0, 4) + '万元');
          // } 
          
          else if (value !== undefined && value.toString().split('.')[0].length <= 4) {
            return (value = value.toString() + '元');
          }
        }

        //////////////
      } else if (value !== undefined && value.toString().length > 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '亿元');
      } else if (value !== undefined && value.toString().length == 9) {
        value = ((value / 100000000) * 100) / 100;
        return (value = value.toString().slice(0, 4) + '亿元');
      } else if (value !== undefined && value.toString().length == 8) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 7) + '万元');
      } else if (value !== undefined && value.toString().length == 7) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 6) + '万元');
      } else if (value !== undefined && value.toString().length == 6) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length == 5) {
        value = ((value / 10000) * 100) / 100;
        return (value = value.toString().slice(0, 5) + '万元');
      } else if (value !== undefined && value.toString().length <= 4) {
        return (value = value + '元');
      }
    },
    //查询所有所属类型
    typeList() {
      categoryAll().then(res => {
        this.filterConditionData = res.data;
        this.filterConditionData.unshift({id: '', name: '全部'});
      });
    },
    // 监听返回的筛选条件
    onFilter(val) {
      this.categoryId = val;
      this.pageList(0);
    },
    // 倒计时
    countdown: function(endTime, showDay = false) {
      const end = Date.parse(formatNewDate(endTime));
      const now = Date.parse(new Date());
      const msec = end - now;
      let result = {
        isOver: false
      };
      if (msec <= 0) {
        result.isOver = true;
      } else {
        let d = parseInt(msec / 1000 / 60 / 60 / 24);
        let h = parseInt(msec / 1000 / 60 / 60);
        let m = parseInt((msec / 1000 / 60) % 60);
        let s = parseInt((msec / 1000) % 60);
        result.hour = h > 9 ? h : '0' + h;
        result.minute = m > 9 ? m : '0' + m;
        result.second = s > 9 ? s : '0' + s;

        if (showDay && result.hour > 24) {
          //开启后大于24小时，显示天数
          result.day = d;
          result.hour = parseInt((msec / 1000 / 60 / 60) % 24);
        }
      }
      return result;
    },
    // 倒计时处理
    countdown_fun() {
      const _this = this;
      let data = _this.biddingList;
      let overLen = 0;

      data.forEach((item, index) => {
        // 计算当前价
        if (item.status === 'bidding' || item.status === 'bided') {
          const now = Date.parse(new Date());
          const overMsec = now - Date.parse(formatNewDate(item.startTime));
          const overSecond = parseInt(overMsec / 1000 / 60);
          if (overSecond < item.continueTime) {
            item.maxAmount = Number(item.upPrice) - overSecond * Number(item.failTactics);

             if (item.maxAmount < item.bottomPrice) {
            item.maxAmount = item.bottomPrice;
            item.maxAmount=this.formatMoney(item.maxAmount)

            }else{
             item.maxAmount=this.formatMoney(item.maxAmount)
            }

          } else {
            item.maxAmount = Number(item.upPrice) - Number(item.continueTime) * Number(item.failTactics);


             if (item.maxAmount < item.bottomPrice) {
            item.maxAmount = item.bottomPrice;
            item.maxAmount=this.formatMoney(item.maxAmount)

          }else{
             item.maxAmount=this.formatMoney(item.maxAmount)
          }


          }

          // if (item.maxAmount < item.bottomPrice) {
          //   item.maxAmount = item.bottomPrice;

          // }
        }

        if (!item.isOver) {
          if (item.status === 'preBid') {
            // 预告中
            let result = _this.countdown(item.startTime, true);
            data[index] = Object.assign(item, result);
            if (result.isOver) {
              //结束处理
              data[index].status = 'bidding';
              data[index].isOver = false;
            }
          } else if (item.status === 'bidding') {
            // 进行中
            let result = _this.countdown(item.endTime, true);
            data[index] = Object.assign(item, result);
            if (result.isOver) {
              //结束处理
              data[index].status = 'bided';
              overLen++;
            }
          } else {
            // 已结束
            data[index].isOver = true;
            data[index].status = 'bided';
            overLen++;
          }
        } else {
          overLen++;
        }
      });

      _this.biddingList = JSON.parse(JSON.stringify(data));
      // console.log(data)
      if (overLen < _this.biddingList.length) {
        _this._timeout = setTimeout(_this.countdown_fun, 1000);
      }
    }
  },
  // 生命周期 - 卸载前
  beforeDestroy() {
    clearTimeout(this._timeout); // 当离开页面时，清除倒计时
  }
};
</script>

<style lang="less" scoped>
.wrap {
  width: 1200px;
  margin: 0 auto;

  .search-box {
    background: #fff;
    margin-top: 20px;
    padding: 10px 24px 10px;

    .screen-status {
      display: flex;
      line-height: 30px;

      .tit {
        width: 64px;
        text-align: justify;
        text-align-last: justify;
      }
      .status-box {
        flex: 1;
        display: flex;
        align-items: center;

        p {
          cursor: pointer;
          padding: 0 10px;
          margin-right: 20px;
        }
      }
    }
  }

  .container {
    margin-top: 20px;

    .content {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;

      .box {
        width: calc(25% - 16px);
        padding: 0 8px;
        margin-bottom: 16px;

        .box-in {
          position: relative;
          background: #fff;
          padding: 13px 13px 20px 13px;

          &.box-in-bidding  {
            p  {
              .price  {
                color: #d91615;
              }
            }
          }
          &.box-in-preBid  {
            p  {
              .price  {
                color: #19a96b;
              }
            }
          }
          &.box-in-bided  {
            p  {
              color: #888888;
            }
          }
          .image {
            width: 100%;
            height: 160px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
          p {
            display: flex;
            align-items: center;
            color: #333333;
            font-size: 14px;
            margin-top: 13px;
            line-height: 1;

            &.name {
              font-size: 16px;
            }
            &.line {
              border-top: 1px solid #e8e8e8;
              padding-top: 14px;
            }
            .tit {
              width: 60px;
              font-size: 14px;
              color: #888888;
              text-align: justify;
              text-align-last: justify;
            }
            .price {
              font-size: 18px;
            }
          }
          .ident {
            position: absolute;
            right: -4px;
            bottom: 13px;
            width: 66px;
            height: 26px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .content2 {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;

      .box {
        width: 33.3%;
        padding: 0 8px;
        margin-bottom: 17px;
        box-sizing: border-box;

        .box-in {
          position: relative;
          background: #fff;
          padding: 17px 16px;
          display: flex;

          .image {
            width: 80px;
            height: 80px;
            overflow: hidden;

            img {
              width: 100%;
            }
          }
          .info {
            margin-left: 16px;

            p {
              display: flex;
              align-items: center;
              color: #888888;
              font-size: 12px;
              margin-bottom: 9px;
              line-height: 17px;

              .tit {
                width: 54px;
                text-align: justify;
                text-align-last: justify;
              }
              &.name {
                font-size: 14px;
                color: #333333;
                margin-top: 2px;
                margin-bottom: 11px;
                width: 190px;
                text-align: justify;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              &.price {
                font-size: 20px;
                color: #ff4400;
                margin-top: 16px;
              }
            }
          }
          .ident {
            position: absolute;
            right: -4px;
            top: 15px;
            width: 66px;
            height: 26px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .bidding-box {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;
      margin-right: -6px;

      .bidding-item {
        width: calc(20% - 12px);
        background: #fff;
        margin: 0 6px 16px 6px;
        padding: 15px 12px 13px 12px;
        box-sizing: border-box;
        border: 1px solid #e3e3e3;
        border-radius: 4px;

        &:hover {
          transition: all 0.4s ease;
          box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.1);

          .hide {
            display: none;
          }
          .btn {
            display: block;
          }
          .btn2 {
            color: #008cf4;
            background: #eef9ff;
            border-color: #5ac0ff;
          }
        }
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .status {
            width: 50px;
            height: 22px;
            text-align: center;
            line-height: 22px;
            border-radius: 4px;
            color: #ffffff;
            font-size: 12px;

            &.pre-bid {
              background: linear-gradient(to right, #00a9ff, #0594f7);
            }
            &.bidding {
              background: linear-gradient(to right, #ff5a48, #ff3d47);
            }
            &.bided {
              background: #bac1c1;
            }
          }
          .name {
            width: 145px;
            font-size: 14px;
            color: #333333;
            line-height: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 12px;
          }
        }
        .bidding-item-top {
          display: flex;
          margin-bottom: 12px;

          .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            overflow: hidden;

            img {
              width: 100%;
              object-fit: fill;
            }
          }
          .info {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .info-item {
              font-size: 12px;
              color: #888888;
              line-height: 1;

              &:last-child {
                margin-top: -1px;
              }
              .tit {
                display: inline-block;
                // width: 50px;
                text-align: justify;
                text-align-last: justify;
              }
              .sign {
                color: #ff4400;

                &.sign2 {
                  color: #666666;
                }
              }
              .price {
                font-size: 14px;
                color: #ff4400;
                font-weight: bold;

                &.price2 {
                  color: #666666;
                }
              }
            }
          }
        }
        .bidding-item-bottom {
          display: flex;
          align-items: center;
          height: 36px;

          .tit {
            width: 60px;
            text-align: right;
            font-size: 12px;
            color: #888888;
          }
          .time-box {
            font-size: 14px;
            color: #333333;
            margin-left: 12px;
          }
        }
        .btn {
          display: none;
          width: 100%;
          height: 36px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          color: #ffffff;
          background: linear-gradient(to right, #ff5a48, #ff3d47);
          border-radius: 4px;
          cursor: pointer;
        }
        .btn2 {
          display: block;
          width: 100%;
          height: 36px;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          color: #666666;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .pager {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: right;
  }
}
</style>
