import server from '@/api';
// console.log('测试')
// server.login.loginIn()
export function loginIn(param) {
  return server.login.loginIn(param);
}

//推荐列表
export function recommendList() {
  return server.goodsInfo.recommendList();
}

//成交公告
export function showMsgPubInfo(params) {
  return server.goodsInfo.showMsgPubInfo({params});
}
//竞标数据
export function biddingInfolistPage(params) {
  return server.goodsInfo.biddingInfolistPage({params});
}
//系统公告新闻动态
export function showNoticeInfo(params) {
  return server.goodsInfo.showNoticeInfo({params});
}

//首页轮播图
export function rotationImgList(params) {
  return server.goodsInfo.rotationImgList({params});
}
//获取成交公告列表
export function getLastMonthsInfo(params) {
  return server.goodsInfo.getLastMonthsInfo(params);
}
//获取新闻详情
export function getNoticeById(params) {
  return server.goodsInfo.getNoticeById({params});
}
//竞买
export function hotReqProduct() {
  return server.goodsInfo.hotReqProduct();
}
//商品列表
export function goodsList(param) {
  return server.goodsInfo.goodsList(param);
}
//商品详情加企业信息
export function goodsDetailAndClient(params) {
  return server.goodsInfo.goodsDetailAndClient({params});
}
//轮播图下面的数据展示
export function transactionDataStatistics(params) {
  return server.goodsInfo.transactionDataStatistics(params);
}

//验证登录名是否存在
export function testVerifyLoginName(param) {
  return server.login.verifyLoginName(param);
}
//验证手机号码是否被使用
export function selectCountByTel(param) {
  return server.login.selectCountByTel(param);
}
//获取验证码
export function getCode(param) {
  return server.login.getCode(param);
}
//校验验证码
export function verifyCode(param) {
  return server.login.verifyCode(param);
}
//注册
export function register(param) {
  return server.login.register(param);
}
//登录
export function login(param) {
  return server.login.login(param);
}
//获取图形验证码
export function getWXImgCode(param) {
  return server.login.getImgCode(param);
}
//根据手机号码修改密码
export function updatePwdByTel(param) {
  return server.login.updatePwdByTel(param);
}
//品种
export function categoryAll(categoryId = null) {
  return server.category.categoryAll(categoryId);
}

//竞买List
export function getProductList(param) {
  return server.proProduct.newProProductList(param);
}

// 竞买中心报价成功列表
export function getQuteSuccessList(param) {
  return server.proProduct.getQuteSuccessList({param});
}

// 获取竞买中心历史批次列表
export function getProductHistoryList(param) {
  return server.proProduct.getProductHistoryList({param});
}

//查询账号类型
export function selectAccountTypeByLoginName(param) {
  return server.users.selectAccountTypeByLoginName(param);
}

// 竞买报价(多个)
export function addProductQuote(param) {
  return server.proProduct.addProductQuote(param);
}

// 竞买报价(单个)
export function addSingleProductQuote(param) {
  return server.proProduct.addSingleProductQuote(param);
}

// 获取竞买详情
export function getProducDetail(params) {
  return server.proProduct.getProducDetail({params});
}
// 获取完成的竞买是否和当前查看用户为同一公司
export function isMyOrder(param) {
  return server.proProduct.isMyOrder(param);
}

//竞卖商品列表查询
export function autionInfolist(params) {
  return server.autionInfo.autionInfolist({params});
}

//查询所有一级分类
export function categoryList(params) {
  return server.autionInfo.categoryList({params});
}

//获取竞卖信息详情
export function autionInfoDteail(params) {
  return server.autionInfo.autionInfoDteail({params});
}

//获取购物车商品数量
export function getCartCount(params) {
  return server.cart.getCartCount({params});
}

//热门竞卖商品
export function hotAutionInfolist(params) {
  return server.hotAution.hotAutionInfolist({params});
}

//出价
export function selectIsAution(params) {
  return server.autionInfo.selectIsAution(params);
}

// 获取用户权限
export function getPermission() {
  server.permission.getPermission().then(res => {
    if (res.errno === 0) {
      if (res.data) {
        let userPermission = JSON.stringify(res.data);
        localStorage.setItem('userPermission', userPermission);
      } else {
        console.log(res.data);
      }
    }
  });
}

//查看我的参与的竞卖数据(可以查看未审核的)
export function readAutionInfoById(params) {
  return server.autionInfo.readAutionInfoById({params});
}
//这个是竞买模板
export function contractTextModel(params) {
  return server.autionInfo.contractTextModel({params});
}

//这个才是真正的查找模板
export function showContractMode(params) {
  return server.autionInfo.showContractMode({params});
}

export function findById(params) {
  return server.users.findById(params);
}

// 获取当前最低价
export function getCurrentMinPrice(params) {
  return server.proProduct.getCurrentMinPrice({params});
}
//根据id查询企业信息
export function selectIsAutionById(params) {
  return server.autionInfo.selectIsAutionById(params);
}
//查看该公司的竞卖商品
export function findByClientInfoId(params) {
  return server.autionInfo.findByClientInfoId({params});
}

//获取竞标中心列表
export function getSelectBiddingList(params) {
  return server.tender.getSelectBiddingList(params);
}
//获取竞标详情
export function getSelectBiddingDetails(params) {
  return server.tender.getSelectBiddingDetails({params});
}
//修改竞标的状态
export function upstart(params) {
  return server.tender.upstart(params);
}
//提交竞标
export function tbidPricesPrices(params) {
  return server.tender.tbidPricesPrices(params);
}
//已报价企业
export function getBiddingProClis(params) {
  return server.tender.getBiddingProClis({params});
}

//登出
export function loginOut() {
  return server.login.loginOut();
}
//提交竞标
export function whetherBided(params) {
  return server.tender.whetherBided(params);
}

// 获取公司logo
export function getCompanyLogo() {
  return server.clientInfo.getCompanyLogo();
}

//南海灵通铝锭最新价格获取
export function newestLvdingPrice() {
  return server.lvDingPrice.newestLvdingPrice();
}
